import * as React from "react"
import About from "../components/about"
import Contact from "../components/contact"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Media from "../components/media"
import MobileNavigation from "../components/mobile-navigation"
import Navigation from "../components/navigation"
import Seo from "../components/seo"
import { useOnScreen } from "../hooks/useOnScreen"

const IndexPage = () => {
  let offset = '-50px';
  if (typeof window !== 'undefined') {
    offset = window.screen.width < 768 ? '-50px' : '-200px';
  }

  const sections = [{ id: 'home', label: 'Home' }, { id: 'about', label: 'Über mich' }, { id: 'media', label: 'In den Medien' }, { id: 'contact', label: 'Kontakt' }];
  const [activeSection, setActiveSection] = React.useState(sections[0].id);

  const homeRef = React.useRef();
  const mediaRef = React.useRef();
  const aboutRef = React.useRef();
  const contactRef = React.useRef();
  const homeOnScreen = useOnScreen(homeRef, offset);
  const mediaOnScreen = useOnScreen(mediaRef, offset);
  const aboutOnScreen = useOnScreen(aboutRef, offset);
  const contactOnScreen = useOnScreen(contactRef, offset);

  React.useEffect(() => {
    let activeSection = '';

    if (homeOnScreen) {
      activeSection = 'home';
    }

    if (aboutOnScreen) {
      activeSection = 'about';
    }

    if (mediaOnScreen) {
      activeSection = 'media';
    }

    if (contactOnScreen) {
      activeSection = 'contact';
    }

    if (activeSection) {
      setActiveSection(activeSection);
    }
  }, [homeOnScreen, mediaOnScreen, aboutOnScreen, contactOnScreen]);

  return (
    <Layout>
      <Seo title="Yasmin Abdullahi" />
      <section ref={homeRef} id="home">
        <Hero />
      </section>

      <Navigation
        sections={sections}
        activeSection={activeSection}
      />

      <MobileNavigation
        sections={sections}
      />

      <section
        ref={aboutRef}
        id="about"
        className="py-16 border-b border-gray-200"
      >
        <About onScreen={aboutOnScreen} />
      </section>

      <section
        ref={mediaRef}
        id="media"
        className="min-h-screen py-16 border-b border-gray-200 bg-gray-100"
      >
        <Media onScreen={mediaOnScreen} />
      </section>

      <section
        ref={contactRef}
        id="contact"
        className="py-16"
      >
        <Contact />
      </section>
    </Layout>
  )
}

export default IndexPage
