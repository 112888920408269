import { Link } from 'gatsby';
import * as React from 'react';
import MediaCard from './media-card';


const Media = ({ onScreen }) => {
    const [show, setShow] = React.useState(false);
    const [showMore, setShowMore] = React.useState(false);
    const variants = {
        hidden: { x: -300, opacity: 0 },
        visible: i => ({
            x: 0,
            opacity: 1,
            transition: {
                delay: i * 0.3,
                type: 'spring',
                duration: 0.8,
                bounce: 0.3,
            },
        }),
    }

    React.useEffect(() => {
        if (onScreen && !show) {
            setShow(true);
        }
    }, [onScreen, show]);

    const handleToggle = () => {
        setShowMore(prevState => !prevState);
    }

    return (
        <div className="container mx-auto px-4 md:px-8">
            <h2 className="text-4xl md:text-5xl text-glp-blue">
                <Link
                    to="#media"
                >
                    <span>In den Medien</span>
                </Link>
            </h2>

            <div className="lg:grid lg:grid-cols-2 gap-x-10 gap-y-2 mt-8">
                <MediaCard
                    show={show}
                    variants={variants}
                    custom={1}
                    heading="Die junge Grünliberale sah sich als Listenfüllerin"
                    lead="Den dritten zusätzlichen Sitz der Grünliberalen holte die Nachwuchstruppe, die neu mit Corina Liebi und Yasmin Abdullahi im Parlament vertreten ist. Letztere rechnete nicht mit der Wahl."
                    author="Rahel Guggisberg"
                    date="30.11.2020"
                    categories={['Berner Zeitung', 'Interview']}
                    link="https://www.bernerzeitung.ch/die-junge-gruenliberale-sah-sich-als-listenfuellerin-346310071193"
                    logo="bz"
                />

                <MediaCard
                    show={show}
                    variants={variants}
                    custom={0}
                    heading="So jung war das Berner Stadtparlament wohl noch nie"
                    lead="Berns Stadtrat ist im Schnitt sechs Jahre jünger als im Jahr 2000. Die Alten haben den Enkelinnen und Enkeln das Vertrauen ausgesprochen, heisst es."
                    author="Dölf Barben, Jael Amina Kaufmann"
                    date="02.12.2020"
                    categories={['Der Bund', 'Interview']}
                    link="https://www.derbund.ch/so-jung-war-das-berner-stadtparlament-wohl-noch-nie-416090923358"
                    logo="bund"
                />

                <MediaCard
                    show={show}
                    variants={variants}
                    custom={3}
                    heading="In der Politik sind alle einmal Neulinge"
                    lead="Drei Semester VWL an der Uni Bern, ein Jahr beim Studierendenrat der SUB und ab 2021 ein Teil des Berner Stadtrats – So schnell kann es gehen. Muss es aber nicht, sagt Yasmin Abdullahi. Politisches Engagement gehe auch ungezwungen."
                    author="Florian Rudolph"
                    date="02.03.2021"
                    categories={['Bärner studizytig']}
                    link="https://www.studizytig.ch/sub-seiten/in-der-politik-sind-alle-einmal-neulinge/"
                    logo={null}
                />

                <MediaCard
                    show={show}
                    variants={variants}
                    custom={2}
                    heading="Zieh nachts Schuhe an, in denen du schnell bist"
                    lead="Die UN nennen es Schattenepidemie. Die Gewalt gegen Frauen steigt weltweit. Zu Hause und im öffentlichen Raum. Und viele Betroffene sexualisierter Gewalt sehen von einer Anzeige ab. Mangels Vertrauen in die Behörden. 6 junge Politikerinnen aus 6 verschiedenen Parteien wissen aus eigener Erfahrung, dass gehandelt werden muss."
                    author="Rahel Zingg"
                    date="22.08.2021"
                    categories={['Schweizer Illustrierte']}
                    link="https://www.schweizer-illustrierte.ch/body-health/mind/zieh-nachts-schuhe-an-in-denen-du-schnell-bist"
                    logo={null}
                />

                {showMore && (
                    <MediaCard
                        show={show}
                        variants={variants}
                        custom={0}
                        heading="Soll die Trinkwasserinitiative angenommen werden?"
                        lead="In diesem Polittalk ging es um die Initiative mit dem langen Titel «Für sauberes Trinkwasser und gesunde Nahrung – Keine Subventionen für den Pestizid- und den prophylaktischen Antibiotika-Einsatz», über die am 13. Juni 2021 abgestimmt wird."
                        author="Ursula Naef"
                        date="17.02.2021"
                        categories={['Schweiz debattiert', 'Podiumsdiskussion']}
                        link="https://schweizdebattiert.ch/2021/02/17/soll-die-trinkwasserinitiative-angenommen-werden/"
                        logo={null}
                    />
                )}

                <div className="col-span-2 ml-auto">
                    <button
                        onClick={handleToggle}
                        className="underline text-gray-800"
                    >
                        {showMore ? 'Weniger Anzeigen' : 'Mehr Anzeigen'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Media;
