import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

const Hero = () => (
    <div className="grid relative">
        <StaticImage
            alt="Yasmin Abdullahi Profil"
            src="../images/hero.jpg"
            layout="fullWidth"
            quality={95}
            className="grid-area-stack h-96 md:h-screen"
        />

        <a
            className="hidden md:block z-10 absolute top-0 right-0 bg-white py-2 px-4 rounded-bl-md"
            href="https://bern.jungegrunliberale.ch/"
            target="_blank"
            rel="noreferrer"
        >
            <StaticImage
                alt="JGLP Logo"
                src="../images/logo.webp"
                placeholder="blurred"
                quality={95}
                width={108}
            />
        </a>

        <div className="grid-area-stack relative grid place-items-end md:mb-20">
            <div className="md:w-3/4 lg:w-2/3 mx-auto px-4 pt-4 md:px-8 md:pt-8 xl:pt-12 xl:pb-6 bg-white rounded-t-md">
                <div className="flex justify-center">
                    <h1>
                        <span className="inline-block md:text-xl text-white bg-green-500 px-2 py-0.5"> Stadträtin Bern,  Junge Grünliberale</span>
                        <div className="text-4xl md:text-7xl text-blue-500 mt-1" style={{ color: 'rgb(43, 170, 223)' }}>Yasmin Abdullahi</div>
                    </h1>
                </div>
            </div>
        </div>

    </div>
);

export default Hero;
