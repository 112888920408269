import * as React from 'react'
import { AnimateSharedLayout } from "framer-motion"
import NavLink from "../components/nav-link"

const Navigation = ({ sections, activeSection }) => (
    <nav className="hidden md:block -mt-20 sticky top-0 z-10">
    <AnimateSharedLayout>
      <ul className="flex justify-center items-center relative bg-white h-20 rounded-t">
        {sections.map(section => (
          <NavLink
            key={section.id}
            to={section.id}
            label={section.label}
            active={activeSection === section.id}
          />
        ))}
      </ul>
    </AnimateSharedLayout>
  </nav>
);

export default Navigation;
