import * as React from 'react';
import { motion } from 'framer-motion';

const Path = props => (
    <motion.path
        fill="transparent"
        strokeWidth="2"
        stroke="currentColor"
        strokeLinecap="round"
        {...props}
    />
);

const MenuButton = ({ isOpen, toggleMenu }) => (
    <button
        className={`fixed z-10 left-4 top-4 text-gray-800 bg-white rounded-full py-3 px-3.5 ${isOpen ? '' : 'shadow'}`}
        onClick={toggleMenu}
    >
        <svg width="23" height="23" viewBox="0 0 23 23" className="mt-1">
            <Path
                animate={isOpen ? { d: "M 3 16.5 L 17 2.5" } : { d: "M 2 2.5 L 20 2.5" }}
            />
            <Path
                d="M 2 9.423 L 20 9.423"
                animate={isOpen ? { opacity: 0 } : { opacity: 1 }}
                transition={{ duration: 0.1 }}
            />
            <Path
                animate={isOpen ? { d: "M 3 2.5 L 17 16.346" } : { d: "M 2 16.346 L 20 16.346" }}
            />
        </svg>
    </button>
);

export default MenuButton;
