import { Dialog } from '@headlessui/react';
import * as React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useWindowSize } from '../hooks/useWindowSize';
import MenuButton from './menu-button';



const MobileNavigation = ({ sections, className }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { height } = useWindowSize();

    const closeMenu = () => setIsOpen(false);
    const toggleMenu = () => setIsOpen(prevState => !prevState);

    return (
        <nav className={`${className ? className : ''}md:hidden`}>
            <MenuButton isOpen={isOpen} toggleMenu={toggleMenu} />

            <Dialog
                open={isOpen}
                onClose={() => { }}
                className="fixed inset-0"
            >
                <motion.div
                    animate={{
                        clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
                        transition: {
                            type: "spring",
                            stiffness: 20,
                            restDelta: 2
                        }
                    }}
                    className="fixed inset-0 bg-white"
                >
                    <div className="pt-24">
                        {sections.map(section => (
                            <Link
                                onClick={closeMenu}
                                key={section.id}
                                to={`#${section.id}`}
                                className="block w-max px-5 py-2 my-2 mx-auto"
                            >
                                {section.label}
                            </Link>
                        ))}
                    </div>
                </motion.div>
            </Dialog>
        </nav>
    );
}

export default MobileNavigation;
