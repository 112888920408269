import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import * as React from 'react'

const About = ({ onScreen }) => {
    const [show, setShow] = React.useState(false);
    const variants = {
        hidden: { opacity: 0 },
        visible: i => ({
            opacity: 1,
            transition: {
                delay: i * 0.3,
            },
        }),
    }

    React.useEffect(() => {
        if (onScreen && !show) {
            setShow(true);
        }
    }, [onScreen, show]);


    return (
        <div className="container mx-auto px-4 md:px-8" style={{hyphens: 'auto'}}>
            <h2 className="text-4xl md:text-5xl text-glp-blue">
                <Link
                    to="#about"
                >
                    <span>Über mich</span>
                </Link>
            </h2>

            <motion.div
                className="flex flex-col md:flex-row mt-8 mb-16"
                variants={variants}
                custom={0}
                initial="hidden"
                animate={show ? 'visible' : 'hidden'}
            >
                <div className="flex-grow-0 flex-shrink-0 text-center">
                    <StaticImage
                        alt="Polaroid Foto"
                        src="../images/private.jpg"
                        aspectRatio={4 / 3}
                        width={384}
                        quality={95}
                        className="rounded-md"
                    />
                </div>
                <div className="max-w-2xl text-justify mt-6 md:mt-0 md:pl-6">
                    <h3 className="inline-block font-semibold text-gray-800">Persönlich</h3>
                    <p className="text-gray-800 mt-2">
                        Ich bin ein «Bärner Meitschi», bin mit meiner Schwester im Fischermätteli aufgewachsen und wohne nun in einer WG in Bümpliz. Meine Freizeit verbringe ich mit Schwimmen, Yoga, «Aareböötle» oder Wandern. Ausserdem verbringe ich gerne Zeit mit meinen Freund:innen bei einem Bier oder einem Cappuccino.
                    </p>
                </div>
            </motion.div>
            <motion.div
                className="flex flex-col md:flex-row mt-8 mb-16"
                variants={variants}
                custom={1}
                initial="hidden"
                animate={show ? 'visible' : 'hidden'}
            >
                <div className="flex-grow-0 flex-shrink-0 text-center">
                    <StaticImage
                        alt="Profil Yasmin Abdullahi"
                        src="../images/yas-job.jpg"
                        aspectRatio={4 / 3}
                        width={384}
                        quality={95}
                        className="rounded-md"
                    />
                </div>
                <div className="max-w-2xl text-justify mt-6 md:mt-0 md:pl-6">
                    <h3 className="inline-block font-semibold text-gray-800">Berufliches</h3>
                    <p className="text-gray-800 mt-2">
                        Ich habe 2018 das zweisprachige Gymnasium in Biel mit Schwerpunkt Spanisch abgeschlossen. Ich studiere zurzeit Volkswirtschaftslehre und Nachhaltige Entwicklung an der Universität Bern.
                    </p>
                    <p className="text-gray-800 mt-2">
                        An der Universität engagiere ich mich in der «Fachschaft Wirtschaftswissenschaften» zuerst im Team Marketing und nun im Social Events Team. Aktuell arbeite ich neben dem Studium als Sachbearbeiterin bei der Mobiliar Versicherung.
                    </p>
                </div>
            </motion.div>
            <motion.div
                className="flex flex-col md:flex-row mt-8 mb-16"
                variants={variants}
                custom={2}
                initial="hidden"
                animate={show ? 'visible' : 'hidden'}
            >
                <div className="flex-grow-0 flex-shrink-0 text-center">
                    <StaticImage
                        alt="Yasmin Abdullahi im Rathaus"
                        src="../images/yas-politic.jpg"
                        aspectRatio={4 / 3}
                        width={384}
                        quality={95}
                        className="rounded-md"
                    />
                </div>
                <div className="max-w-2xl text-justify mt-6 md:mt-0 md:pl-6">
                    <h3 className="inline-block font-semibold text-gray-800">Politisch</h3>
                    <p className="text-gray-800 mt-2">
                        Ich wurde politisiert, weil mehrere Entscheidungen nicht meinen Vorstellungen entsprachen und ich finde, dass bei einigen Themen, zum Beispiel Klimaschutz oder Gleichstellung, viel zu wenig gemacht wird. Die Verärgerung habe ich in Entschlossenheit umgewandelt und bin der JGLP beigetreten, um die Politik aktiv mitgestalten zu können.
                    </p>
                    <p className="text-gray-800 mt-2">
                        Ich möchte mich insbesondere einsetzen für mehr Schutz für die Umwelt, die Reduktion von Foodwaste, Chancengleichheit in den Schulen und eine attraktive Gestaltung des öffentlichen Raums.
                    </p>
                    <p className="text-gray-800 mt-2">
                        An der Universität Bern engagierte ich mich im Studierendenrat für die JGLP und war zudem Mitglied der Sozialfondskommision. Im November 2020 wurde ich in den Berner Stadtrat gewählt und bin dort ein Teil der GLP/JGLP Fraktion.
                    </p>
                </div>
            </motion.div>

            <div>
                <div className="md:w-3/4 mx-auto">
                    <StaticImage
                        alt="Smartvote Spider Grafik"
                        src="../images/smartvote-spider.png"
                    />
                </div>
            </div>
        </div>

    );
}

export default About;
