import * as React from 'react';
import { motion } from "framer-motion";
import { Link } from 'gatsby';

const NavLink = ({ label, to, active }) => (
    <li
        className={'flex flex-col items-center justify-start'}
    >
        <Link
            to={`#${to}`}
            className="font-semibold block text-gray-800 px-6 py-6"
        >
            {label}
            <div className="relative">
                {active && (
                    <motion.div
                        layoutId="nav"
                        className="absolute top-1 h-1 w-full rounded-full bg-gray-800" />
                )}
            </div>
        </Link>
    </li>
);

export default NavLink;
