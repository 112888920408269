import { Field, Formik, ErrorMessage } from 'formik';
import { motion } from 'framer-motion';
import * as React from 'react';
import { navigate } from "gatsby";

// This function encodes the captured form data in the format that Netlify's backend requires
function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}



const ContactForm = () => {
    const formName = 'contact';
    const [submitError, setSubmitError] = React.useState(false);

    const handleSubmit = values => {
        setSubmitError(false);
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': formName,
                ...values
            })
        })
            .then(response => {
                if (!response.ok) {
                    setSubmitError(true);
                    throw new Error('Could not send E-Mail');
                }

                navigate('/success/')
            })
            .catch(error => console.error(error));
    };

    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                message: '',
            }}
            validate={values => {
                const errors = {};

                if (!values.name) {
                    errors.name = 'Bitte Name angeben.'
                }

                if (!values.email) {
                    errors.email = 'Bitte E-Mail angeben.';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                    errors.email = 'Bitte gültige E-Mail angeben';
                }

                if (!values.message) {
                    errors.message = 'Bitte Nachricht verfassen.';
                }

                return errors;
            }}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit }) => (
                <form name="contact" data-netlify="true" method="POST" action="/" onSubmit={handleSubmit}>
                    {/* Netlify: You still need to add the hidden input with the form name to your JSX form */}
                    <input type="hidden" name="form-name" value="contact" />

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8">
                        <label htmlFor="name" className="block">
                            Name
                            <Field name="name" type="text" className="block w-full bg-gray-500 mt-2" />
                            <ErrorMessage name="name" render={msg => (
                                <motion.div
                                    initial={{ x: -180, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    className="text-glp-blue mt-1"
                                >
                                    {msg}
                                </motion.div>
                            )} />
                        </label>

                        <label htmlFor="email" className="block">
                            E-Mail
                            <Field name="email" type="email" className="block w-full bg-gray-500 mt-2" />
                            <ErrorMessage name="email" render={msg => (
                                <motion.div
                                    initial={{ x: -180, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    className="text-glp-blue mt-1"
                                >
                                    {msg}
                                </motion.div>
                            )} />
                        </label>

                        <label htmlFor="message" className="block md:col-span-2">
                            Deine Nachricht
                            <Field name="message" as="textarea" rows="5" className="block w-full bg-gray-500 mt-2" />
                            <ErrorMessage name="message" render={msg => (
                                <motion.div
                                    initial={{ x: -180, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    className="text-glp-blue mt-1"
                                >
                                    {msg}
                                </motion.div>
                            )} />
                        </label>

                        <div className="md:col-span-2 flex justify-end items-center">

                            {submitError && (
                                <div className="text-red-500 pr-1">E-Mail konnte nicht gesendet werden.</div>
                            )}

                            <button type="submit" className="px-4 py-2 border border-gray-500 hover:bg-gray-500">
                                Senden
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    );
}

export default ContactForm;
